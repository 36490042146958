import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import { actionCreator, types } from "../../store";
import ErrorModal from "../../components/Common/ErrorModal";
import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  DropdownToggle,
  UncontrolledDropdown,
  Tooltip,
} from "reactstrap";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  RFIDCode,
  SerialNo,
  Status,
  CurrentLocation,
  TypeOfWeapon,
  Date,
} from "./column";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import "./customStyles.css";

const Commission = ({ commission, locations, equipment, ...props }) => {
  const [selectedCommission, setSelectedCommission] = React.useState();
  const [modal, setModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(false);
  const [rfidCodeList, setRfidCodeList] = React.useState([]);

  const [rfidTag, setRfidTag] = React.useState({
    barrel: "",
    upper: "",
    lower: "",
    scope: "",
  });
  const [ws, setWs] = React.useState(null);
  const [topBarrel, setTopBarrel] = React.useState(false);
  const [topUpper, setTopUpper] = React.useState(false);
  const [topLower, setTopLower] = React.useState(false);
  const [topScope, setTopScope] = React.useState(false);
  const [selectedPart, setSelectedPart] = React.useState([]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      butt_number: (selectedCommission && selectedCommission.butt_number) || "",
      serial_number:
        (selectedCommission && selectedCommission.serial_number) || "",
      location_id: (selectedCommission && selectedCommission.location_id) || "",
      manufacturing_date:
        (selectedCommission && selectedCommission.manufacturing_date) || "",
      status: (selectedCommission && selectedCommission.status) || "",
      type: (selectedCommission && selectedCommission.type) || "",
    },
    validationSchema: Yup.object({
      location_id: Yup.string().required("Please Select the Current Location"),
      status: Yup.string().required("Please Select Status"),
      type: Yup.string().required("Please Select Type of Weapon/Optical"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateCommission = {
          id: selectedCommission.id,
          butt_number: values.butt_number,
          serial_number: values.serial_number,
          location_id: parseInt(values.location_id),
          status: parseInt(values.status),
          type: parseInt(values.type),
          manufacturing_date: values.manufacturing_date,
          barrel_tag: rfidTag.barrel || "",
          upper_tag: rfidTag.upper || "",
          lower_tag: rfidTag.lower || "",
          scope_tag: rfidTag.scope || "",
        };
        // update commission
        props.actionCreator({
          type: types.UPDATE_COMMISSION,
          payload: updateCommission,
        });

        validation.resetForm();
        setIsEdit(false);
      } else {
        const newCommission = {
          butt_number: values["butt_number"],
          serial_number: values["serial_number"],
          location_id: parseInt(values["location_id"]),
          status: parseInt(values["status"]),
          type: parseInt(values["type"]),
          manufacturing_date: values["manufacturing_date"],
          barrel_tag: rfidTag.barrel || "",
          upper_tag: rfidTag.upper || "",
          lower_tag: rfidTag.lower || "",
          scope_tag: rfidTag.scope || "",
        };
        // save new commission
        props.actionCreator({
          type: types.ADD_NEW_COMMISSION,
          payload: newCommission,
        });

        validation.resetForm();
      }
      toggle();
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        Cell: (location) => {
          return location.row.original.id;
        },
      },
      {
        Header: "Serial No.",
        accessor: "serial_number",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <SerialNo {...cellProps} />;
        },
      },
      {
        Header: "Butt No.",
        filterable: false,
        disableFilters: true,
        accessor: "butt_number",
        Cell: (commission) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {commission.row.original.butt_number}
              </Link>
            </h5>
            <p className="text-muted mb-0">
              {commission.row.original.serial_number}
            </p>
          </>
        ),
      },
      {
        Header: "Type of Weapon/Optical",
        accessor: "type_name",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <TypeOfWeapon {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        disableFilters: true,
        width: "20%",
        Cell: (cellProps) => {
          return (
            <>
              <Status {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Current Location",
        accessor: "location_name",
        filterable: false,
        disableFilters: true,
        width: "20%",
        Cell: (cellProps) => {
          return (
            <>
              <CurrentLocation {...cellProps} />{" "}
            </>
          );
        },
      },

      {
        Header: "Barrel Tag",
        accessor: "barrel_tag",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <SerialNo {...cellProps} />;
        },
      },
      {
        Header: "Upper Tag",
        accessor: "upper_tag",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <SerialNo {...cellProps} />;
        },
      },
      {
        Header: "Lower Tag",
        accessor: "lower_tag",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <SerialNo {...cellProps} />;
        },
      },
      {
        Header: "Scope Tag",
        accessor: "scope_tag",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <SerialNo {...cellProps} />;
        },
      },

      {
        Header: "Date Modified",
        accessor: "date_modified",
        filterable: false,
        disableFilters: true,
        width: "20%",
        Cell: (cellProps) => {
          return (
            <>
              <Date {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const commissionData = cellProps.row.original;
                    handleCommissionClick(commissionData);
                    setRfidCodeList([]);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_COMMISSION });
    toast(commission.message, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });
  };

  const handleErrorNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_COMMISSION_ERROR_MESSAGE });
    toast(commission.errorMessage, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-danger text-white",
    });
  };

  React.useEffect(() => {
    commission.isCommissionSubmitted && handleNotification();

    commission.error &&
      commission.errorMessage &&
      commission.status != 500 &&
      commission.status != 403 &&
      handleErrorNotification();
  }, [commission]);

  React.useEffect(() => {
    initiateWebSocketConnection();
    props.actionCreator({
      type: types.GET_COMMISSION_LIST,
    });
    props.actionCreator({
      type: types.GET_LOCATION_LIST,
    });
    props.actionCreator({
      type: types.GET_EQUIPMENT_LIST,
    });
    setIsEdit(false);
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const initiateWebSocketConnection = () => {
    const socket = new WebSocket("wss://rfid-socket.mpgapps.site");

    socket.addEventListener("open", () => {
      console.log("Connected");
      setWs(socket);
    });

    socket.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);
      if (Object.keys(data).length !== 0) {
        setRfidCodeList((prevWeapons) => {
          let newWeapons = [...prevWeapons];
          const isExist = newWeapons.some(
            (code) => code.rfid_code == data.rfid_code
          );
          if (!isExist) {
            newWeapons.push({
              rfid_code: data.rfid_code,
              part: ""
            });
          }
          return newWeapons;
        });
      }
    });

    socket.addEventListener("close", () => {
      console.log("Disconnected");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    });
  };

  const toggle = () => {
    setModal(!modal);
  };

  const handleCommissionClick = (arg) => {
    const commission = arg;
    setSelectedCommission({
      id: commission.id,
      butt_number: commission.butt_number,
      serial_number: commission.serial_number,
      location_id: commission.location_id,
      status: commission.status,
      type: commission.type,
      manufacturing_date: commission.manufacturing_date,
    });

    setRfidTag({
      barrel: commission.barrel_tag || "",
      upper: commission.upper_tag || "",
      lower: commission.lower_tag || "",
      scope: commission.scope_tag || "",
    });

    setIsEdit(true);

    toggle();
  };

  const node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete commission modal
  const [deleteModal, setDeleteModal] = React.useState(false);

  const onClickDelete = (commission) => {
    setSelectedCommission(commission);
    setDeleteModal(true);
  };

  const handleDeleteCommission = () => {
    props.actionCreator({
      type: types.DELETE_COMMISSION,
      payload: {
        id: selectedCommission.id,
      },
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleCommissionClicks = () => {
    setRfidTag({
      barrel: "",
      upper: "",
      lower: "",
      scope: "",
    });
    setSelectedCommission({});
    setIsEdit(false);
    toggle();
  };

  const handleExportClicks = async () => {
    await props.actionCreator({
      type: types.EXPORT_COMMISSION,
      payload: {},
    });
  };

  
  const onChangeSelect2 = (e, index, value) => {
    const part = e.target.value;
    const newSelections = [...rfidCodeList];
    
    setRfidTag((prevSelections) => {
        return {
          ...prevSelections,
          [part]: value
        };
    });

    newSelections.forEach((_, i) => {
      if (i !== index && newSelections[i].part === part) {
        newSelections[i].part = ""; 
      }
    });

    newSelections[index].part = part; // Update the current select
    setSelectedPart(newSelections);
  };

  //meta title
  document.title = "Commission | RFID";
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCommission}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ErrorModal
        component={commission}
        statusCode={commission.status}
        show={errorModal}
        onCloseClick={() => setErrorModal(false)}
        message={commission.errorMessage}
        setErrorModal={setErrorModal}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Commission" breadcrumbItem="Commission" />
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      RFID List{" "}
                      <span className="text-muted fw-normal ms-2">
                        ({commission.data.length})
                      </span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <Link
                        to="#"
                        className="btn btn-light"
                        onClick={handleCommissionClicks}
                      >
                        <i className="bx bx-plus me-1"></i> Add New
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="#"
                        className="btn btn-light"
                        onClick={handleExportClicks}
                      >
                        <i className="bx bx-file me-1"></i> Export
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={commission.data}
                    isGlobalFilter={true}
                    customPageSize={10}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />

                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    style={{ maxWidth: "1100px" }}
                  >
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Commission" : "New Commission"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={4}>
                            <div className="mb-3">
                              <Label className="form-label">Serial No.</Label>
                              <Input
                                name="serial_number"
                                label="Serial No."
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.serial_number || ""}
                                invalid={
                                  validation.touched.serial_number &&
                                  validation.errors.serial_number
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Butt No.</Label>
                              <Input
                                name="butt_number"
                                label="Butt No."
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.butt_number || ""}
                                invalid={
                                  validation.touched.butt_number &&
                                  validation.errors.butt_number
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">
                                Type of Weapon/Optical
                              </Label>
                              <Input
                                type="select"
                                name="type"
                                className="form-select"
                                multiple={false}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.type || ""}
                                invalid={
                                  validation.touched.type &&
                                  validation.errors.type
                                    ? true
                                    : false
                                }
                              >
                                <option value={""}>
                                  Select Weapon/Optical
                                </option>
                                {equipment.data.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </Input>
                              {validation.touched.type &&
                              validation.errors.type ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.type}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">
                                Current Location
                              </Label>
                              <Input
                                type="select"
                                name="location_id"
                                className="form-select"
                                multiple={false}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.location_id || ""}
                                invalid={
                                  validation.touched.location_id &&
                                  validation.errors.location_id
                                    ? true
                                    : false
                                }
                              >
                                <option value={""}>
                                  Select Current Location
                                </option>
                                {locations.data.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.location_name}
                                  </option>
                                ))}
                              </Input>
                              {validation.touched.location_id &&
                              validation.errors.location_id ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.location_id}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">
                                Manufacturing Date
                              </Label>
                              <Input
                                type="date"
                                name="manufacturing_date"
                                className="form-select"
                                multiple={false}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.manufacturing_date || ""
                                }
                                invalid={
                                  validation.touched.manufacturing_date &&
                                  validation.errors.manufacturing_date
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Status</Label>
                              <Input
                                type="select"
                                name="status"
                                className="form-select"
                                multiple={false}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.status || "0"}
                                invalid={
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? true
                                    : false
                                }
                              >
                                <option value={""}>Select Status</option>
                                <option value={1}>Active</option>
                                <option value={2}>Inactive</option>
                              </Input>
                              {validation.touched.status &&
                              validation.errors.status ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.status}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={8}>
                            <Row className="pt-3">
                              <Col xs={3}>
                                <label className="form-label form-label mb-0 font-size-13">
                                  Barrel RFID Tag
                                </label>
                                {rfidTag.barrel && (
                                  <Tooltip
                                    placement="top"
                                    isOpen={topBarrel}
                                    target="TooltipTBarrel"
                                    toggle={() => {
                                      setTopBarrel(!topBarrel);
                                    }}
                                    style={{
                                      fontSize: "17px",
                                      padding: "10px",
                                      backgroundColor: "#2c302ef2",
                                    }}
                                  >
                                    {rfidTag.barrel}
                                  </Tooltip>
                                )}
                                <p
                                  className="text-muted mb-0 rfid-tags-label"
                                  id="TooltipTBarrel"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {rfidTag.barrel || "-"}
                                </p>
                              </Col>
                              <Col xs={3}>
                                <label className="form-label form-label mb-0 font-size-13">
                                  Upper RFID Tag
                                </label>
                                {rfidTag.upper && (
                                  <Tooltip
                                    placement="top"
                                    isOpen={topUpper}
                                    target="TooltipUpper"
                                    toggle={() => {
                                      setTopUpper(!topUpper);
                                    }}
                                    style={{
                                      fontSize: "17px",
                                      padding: "10px",
                                      backgroundColor: "#2c302ef2",
                                    }}
                                  >
                                    {rfidTag.upper || "-"}
                                  </Tooltip>
                                )}
                                <p
                                  className="text-muted mb-0 rfid-tags-label"
                                  id="TooltipUpper"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {rfidTag.upper || "-"}
                                </p>
                              </Col>
                              <Col xs={3}>
                                <label className="form-label form-label mb-0 font-size-13">
                                  Lower RFID Tag
                                </label>
                                {rfidTag.lower && (
                                  <Tooltip
                                    placement="top"
                                    isOpen={topLower}
                                    target="TooltipLower"
                                    toggle={() => {
                                      setTopLower(!topLower);
                                    }}
                                    style={{
                                      fontSize: "17px",
                                      padding: "10px",
                                      backgroundColor: "#2c302ef2",
                                    }}
                                  >
                                    {rfidTag.lower}
                                  </Tooltip>
                                )}
                                <p
                                  className="text-muted mb-0 rfid-tags-label"
                                  id="TooltipLower"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {rfidTag.lower || "-"}
                                </p>
                              </Col>
                              <Col xs={3}>
                                <label className="form-label form-label mb-0 font-size-13">
                                  Scope RFID Tag
                                </label>
                                {rfidTag.scope && (
                                  <Tooltip
                                    placement="top"
                                    isOpen={topScope}
                                    target="TooltipScope"
                                    toggle={() => {
                                      setTopScope(!topScope);
                                    }}
                                    style={{
                                      fontSize: "17px",
                                      padding: "10px",
                                      backgroundColor: "#2c302ef2",
                                    }}
                                  >
                                    {rfidTag.scope}
                                  </Tooltip>
                                )}
                                <p
                                  className="text-muted mb-0 rfid-tags-label"
                                  id="TooltipScope"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {rfidTag.scope || "-"}
                                </p>
                              </Col>
                            </Row>
                            <Row className="pt-3">
                              <div
                                style={{
                                  height: "320px",
                                  backgroundColor: "#f8f9fa",
                                  overflowY: "scroll",
                                }}
                              >
                                <Row
                                  className="pt-3"
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                  }}
                                >
                                  {rfidCodeList &&
                                    rfidCodeList.map((item, index) => (
                                      <Col
                                        key={`rfid-code${index}`}
                                        xs={4}
                                        style={{
                                          width: "100%",
                                          paddingBottom: "20px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            border: "1px solid #adb5bd",
                                            padding: "11px",
                                            backgroundColor: "white",
                                          }}
                                        >
                                          <Row>
                                            <Col xs={12}>
                                              <label className="form-label form-label mb-2 font-size-11">
                                                {rfidCodeList[`${index}`] && rfidCodeList[`${index}`].rfid_code}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col xs={12}>
                                              <Input
                                                type="select"
                                                // name="type"
                                                value={item.part}
                                                name={`select_type.${index}`}
                                                className="form-select tag-select"
                                                multiple={false}
                                                onChange={(e) => {
                                                  onChangeSelect2(
                                                    e,
                                                    index,
                                                    rfidCodeList[`${index}`].rfid_code
                                                  );
                                                }}
                                                style={{
                                                  backgroundColor: "#4795d9",
                                                  color: "white",
                                                  width: "115px",
                                                  height: "35px",
                                                  float: "right",
                                                }}
                                              >
                                                <option value={""}>
                                                  Select
                                                </option>
                                                <option value={"barrel"}>
                                                  Barrel Tag
                                                </option>
                                                <option value={"upper"}>
                                                  Upper Tag
                                                </option>
                                                <option value={"lower"}>
                                                  Lower Tag
                                                </option>
                                                <option value={"scope"}>
                                                  Scope Tag
                                                </option>
                                              </Input>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                    ))}
                                </Row>
                                
                              </div>
                            </Row>
                            <Row>
                                  <Col>
                                    <div className="text-end pt-2">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                          setRfidCodeList([]);
                                        }}
                                      >
                                        Clear Tag List
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ commission, locations, equipment }) => ({
  commission,
  locations,
  equipment,
});

export default connect(mapStateToProps, { actionCreator })(Commission);
