import React from "react";
import { connect } from "react-redux";
import { Container, Button } from "reactstrap";
import { actionCreator, types } from "../../../store";
import { Link } from "react-router-dom";

// components
import OverAllBar from "./OverAllBar";
import AuditTrail from "./AuditTrail";
import SerialLiveLocation from "./SerialLiveLocation";

// icons
import FeatherIcon from "feather-icons-react";
import stEngrLogo from "../../../assets/images/ste-logo.png";

const RFIDDashboard = ({ dashboard, ...props }) => {
  const [ws, setWs] = React.useState(null);
  const [liveSerialLogsSource, setLiveSerialLogsSource] = React.useState([]);
  const [overall, setOverAll] = React.useState(0);
  const [fullScreenElement, setFullScreenElement] = React.useState(false);

  React.useEffect(() => {
    initiateWebSocketConnection();
    getLiveSerialLogs();
    getAuditTrail();
    // Cleanup function to close WebSocket connection when component unmounts
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  React.useEffect(() => {
    dashboard.serialLogs.data.length > 0 && setSerialLogsData();
  }, [dashboard]);

  React.useEffect(() => {
    getOverAllTotal();
  }, [liveSerialLogsSource]);

  const toggleFullScreen = () => {
    const element = document.documentElement;

    if (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      setFullScreenElement(false);
      if (document.exitFullscreen) {
        document
          .exitFullscreen()
          .catch((error) => console.error("Failed to exit fullscreen:", error));
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document
          .mozCancelFullScreen()
          .catch((error) => console.error("Failed to exit fullscreen:", error));
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document
          .webkitExitFullscreen()
          .catch((error) => console.error("Failed to exit fullscreen:", error));
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document
          .msExitFullscreen()
          .catch((error) => console.error("Failed to exit fullscreen:", error));
      }
    } else {
      setFullScreenElement(true);
      if (element.requestFullscreen) {
        element
          .requestFullscreen()
          .catch((error) =>
            console.error("Failed to enter fullscreen:", error)
          );
      } else if (element.mozRequestFullScreen) {
        /* Firefox */
        element
          .mozRequestFullScreen()
          .catch((error) =>
            console.error("Failed to enter fullscreen:", error)
          );
      } else if (element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        element
          .webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
          .catch((error) =>
            console.error("Failed to enter fullscreen:", error)
          );
      } else if (element.msRequestFullscreen) {
        /* IE/Edge */
        element
          .msRequestFullscreen()
          .catch((error) =>
            console.error("Failed to enter fullscreen:", error)
          );
      }
    }
  };

  const getOverAllTotal = () => {
    let total = 0;
    liveSerialLogsSource.map((item) => {
      total = total + item.serials.length;
    });
    setOverAll(total);
  };

  const getLiveSerialLogs = () => {
    props.actionCreator({
      type: types.GET_SERIAL_LOGS,
    });
  };

  const getAuditTrail = () => {
    props.actionCreator({
      type: types.GET_AUDIT_TRAIL,
      payload: {
        limit: 10,
      },
    });
  };

  const setSerialLogsData = () => {
    const result = regroupByLocation(dashboard.serialLogs.data);

    setLiveSerialLogsSource(result);
  };

  const regroupByLocation = (data) => {
    const groupedData = {};
    data.forEach((item) => {
      const { location_name, serial_number } = item;
      if (!groupedData[location_name]) {
        groupedData[location_name] = {
          location_name: location_name,
          serials: [],
        };
      }
      if (serial_number) {
        groupedData[location_name].serials.push({ serial_number });
      }
    });

    // Convert object to array
    const dataArray = Object.values(groupedData);

    // Sort the array by location_name alphabetically
    dataArray.sort((a, b) => a.location_name.localeCompare(b.location_name));

    return dataArray;
  };

  const today = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = today.getFullYear();
    const dateString = `${day}/${month}/${year}`;

    return dateString;
  };

  const initiateWebSocketConnection = () => {
    const socket = new WebSocket("wss://rfid-socket.mpgapps.site");

    socket.addEventListener("open", () => {
      console.log("...");
      setWs(socket);
    });

    socket.addEventListener("message", (event) => {
      getLiveSerialLogs();
      getAuditTrail();
      console.log("Received:", event.data);
    });

    socket.addEventListener("close", () => {
      console.log("xxx");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };

  //meta title
  document.title = "Dashboard | RFID";

  return (
    <React.Fragment>
      <div className="pt-3 mx-2">
        <Container fluid>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 30,
              marginTop: 5,
            }}
          >
            <span className="logo-lg">
              <img src={stEngrLogo} alt="" height="24" />{" "}
              <span className="logo-txt">ST Engineering</span>
            </span>
            <Link
              onClick={toggleFullScreen}
              style={{
                color: "black",
              }}
            >
              {fullScreenElement ? (
                <FeatherIcon icon="minimize-2" />
              ) : (
                <FeatherIcon icon="maximize-2" />
              )}
            </Link>
          </div>

          <div
            className="rfid-container col-md-12"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              className="analytics-container col-md-4"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 25,
              }}
            >
              <h5
                className="card-title"
                style={{
                  fontSize: 20,
                  marginRight: 20,
                  marginTop: -5,
                }}
              >
                Analytics
              </h5>
              <i className="dripicons-calendar"> {today()}</i>
            </div>
            <OverAllBar totalSerialLogs={overall} />
          </div>
          <div
            className="audit-container"
            style={{
              display: "flex",
              marginTop: 20,
            }}
          >
            <SerialLiveLocation liveSerialLogsSource={liveSerialLogsSource} />
            <AuditTrail auditLogs={dashboard.auditLogs.data} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  dashboard,
});

export default connect(mapStateToProps, { actionCreator })(RFIDDashboard);
